<template>
	<div>
		<div class="column is-full">
			<div class="card events-card is-size-6">
				<header class="card-header">
					<p class="card-header-title">
					Secretos
					</p>
					<a href="#" class="card-header-icon" aria-label="more options">
						<span class="icon">
							<i class="fa fa-angle-down" aria-hidden="true"></i>
						</span>
					</a>
				</header>
				<div class="card-table">
					<div class="content">
						<table class="table is-fullwidth is-striped is-size-7">
							<thead v-if="secrets.length > 0">
								<th></th>
								<th>Descripción</th>
								<th>Código</th>
								<th></th>
							</thead>
							<tbody>
								<tr v-if="secrets.length === 0">
									<td width="5%"><i class="fa fa-bell-o"></i></td>
									<td>Sin secretos</td>
								</tr>
								<tr v-for="localSecret in secrets" :key="localSecret.id">
									<td width="5%"><i class="fa fa-bell-o"></i></td>
									<td>{{ localSecret.name }}</td>
									<td>{{ localSecret.secret }}</td>
									<td class="level-right">
										<div class="buttons">
											<a class="button is-small is-primary is-light is-info" @click.prevent="getSecret(localSecret.id)">
												<span class="icon">
													<i class="fa fa-edit"></i>
												</span>
											</a>
											<a v-if="hasWritePermission" class="button is-small is-primary is-light is-danger has-text-left" @click.prevent="deleteSecret(localSecret.id)">
												<span class="icon">
													<i class="fa fa-trash"></i>
												</span>
											</a>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<footer class="card-footer">
					<a v-if="hasWritePermission" @click.prevent="eventModalSecrets()" class="card-footer-item button is-primary is-light">
						<span class="icon is-small">
							<i class="fa fa-plus-square"></i>
						</span>
						<span>Nuevo</span>
					</a>
				</footer>
			</div>
		</div>
		<!-- MODAL -->
		<div class="modal" id="modalSecrets">
			<div class="modal-background"></div>
			<div class="modal-card">
				<header class="modal-card-head">
					<p class="modal-card-title">Agregar secreto</p>
					<button class="delete"  @click.prevent="eventModalSecrets()" aria-label="close"></button>
				</header>
				<section class="modal-card-body">
					<label class="label" for="#secretCompany">Nombre:</label>
					<input
						v-model="tmpSecret.name"
						class="input"
						type="text"
						id="secretCompany"
						placeholder="Nombre"
					>
					<label class="label" for="#secretCode">Código:</label>
					<input
						v-model="tmpSecret.secret"
						class="input"
						type="text"
						id="secretCode"
						placeholder="Código"
					>
				</section>
				<footer class="modal-card-foot">
					<button v-if="hasWritePermission" class="button is-success" @click.prevent="addSecret()">Agregar</button>
					<button v-if="hasWritePermission" class="button" @click.prevent="cancelModal()">Cancelar</button>
					<button v-else class="button" @click.prevent="cancelModal()">Cerrar</button>
				</footer>
			</div>
		</div>
	</div>
</template>
<script>
export default {
  data: () => ({
    secrets: [],
    error: false,
    strError: '',
    tmpSecret: { id: '', name: '', secret: '', company_id: '' }
  }),
  props: {
		company: Object,
		hasWritePermission: Boolean
  },
  methods: {
		cancelModal () {
			this.tmpSecret.id = ''
			this.tmpSecret.name = ''
			this.tmpSecret.secret = ''
			this.eventModalSecrets()
		},
    eventModalSecrets () {
      var Modal = document.querySelector(`#modalSecrets`)
      Modal.classList.toggle('is-active')
    },
    addSecret () {
			this.tmpSecret.company_id = this.company.id
			this.$axios
			.post('company/createSecret', { secret: this.tmpSecret })
			.then(response => {
				const result = response.data.result
				console.log(result)
				if (result === 'OK') {
					this.tmpSecret.id = ''
					this.tmpSecret.name = ''
					this.tmpSecret.secret = ''
					this.updateList()
					this.eventModalSecrets()
				}
			})
			.catch(error => console.log(error))
    },
		deleteSecret (secretId) {
			this.tmpSecret.id = secretId
			this.$axios
			.post('company/deleteSecret', { secret: this.tmpSecret })
			.then(response => {
				const result = response.data.result
				if (result === 'OK') {
					this.updateList()
				} else {
					alert('Ha ocurrido un error')
				}
			})
			.catch(error => console.log(error))
    },
		getSecret (secretId) {
			this.tmpSecret.id = secretId
			this.$axios
			.get('company/getSecret', { params: { secret_id: secretId } })
			.then(response => {
				console.log('here')
				this.tmpSecret = response.data.secret
				this.eventModalSecrets()
			})
			.catch(error => console.log(error))

      // this.contacts.push({rut: this.tmpContact.rut, name: this.tmpContact.name, phone: this.tmpContact.phone, email: this.tmpContact.email})
      
    },
		async updateList() {
			// console.log('updateList()')
			this.$axios
			.get('company/getSecrets', { params: { company_id: this.company.id } })
			.then(response => {
				// console.log(response.data.secrets)
				this.secrets = response.data.secrets
			})
			.catch(error => console.log(error))
    }
  },
	watch: {
		company: function(newVal) { // watch it
			this.company = newVal
			this.updateList()
		}
	}
}
</script>