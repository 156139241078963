<template>
	<section>
		<div class="column">
			<div class="card events-card is-size-6">
				<header class="card-header">
					<p class="card-header-title">
					Facturación (Saldo: ${{ formatPrice(company.balance) }})
					</p>
					<a href="#" class="card-header-icon" aria-label="more options">
						<span class="icon">
							<i class="fas fa-angle-down" aria-hidden="true"></i>
						</span>
					</a>
				</header>
				<div class="card-table">
					<div class="table-container">
						<table class="table is-fullwidth is-striped is-size-7">
							<thead v-if="invoices.length > 0">
								<th class="is-hidden-mobile"></th>
								<th class="">Periodo</th>
								<th>Total</th>
								<th class="">Estado</th>
								<th></th>
							</thead>
							<tbody>
								<tr v-if="invoices.length === 0">
									<td width="5%"><i class="fa-regular fa-file-invoice is-size-4"></i></td>
									<td>Sin Facturas</td>
								</tr>
								<tr v-for="localInvoice in invoices" :key="localInvoice.id">
									<td class="is-hidden-mobile is-size-5" width="5%"><i class="fa fa-file-invoice-dollar"></i></td>
									<td class="is-family-monospace is-size-6">{{ getMonthName(localInvoice.period.month, localInvoice.period.year) }}/{{ localInvoice.period.year }}</td>
									<td class="has-text-weight-semibold">${{ formatPrice(localInvoice.total) }}</td>
									<td class="">
                    <button v-if="localInvoice.paid" class="button is-success"><span class="icon is-small"><i class="fa-regular fa-face-smile"></i></span></button>
                    <button v-else-if="hasWritePermission" class="button is-danger" @click.prevent="clickStatus(localInvoice)"><span class="icon is-small"><i class="fa-regular fa-face-sad-tear"></i></span></button>
                    <button v-else class="button is-danger"><span class="icon is-small"><i class="fa-regular fa-face-sad-tear"></i></span></button>
                  </td>
									<td>
                    <div class="buttons level-right">
											<a class="button is-primary is-light is-info" @click.prevent="eventModalInvoice(localInvoice)">
												<span class="icon">
													<i class="fa fa-circle-info"></i>
												</span>
											</a>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
        <footer v-if="hasWritePermission" class="card-footer">
					<a class="card-footer-item button is-primary is-light" @click.prevent="eventModalPay()">
            <span class="icon is-small">
              <i class="fa-solid fa-dollar-sign"></i>
            </span>
						<span>Abonar</span>
					</a>
				</footer>
			</div>
		</div>
		<div class="modal" id="modalInvoice">
			<div class="modal-background"></div>
			<div class="modal-card">
				<header class="modal-card-head">
				<p class="modal-card-title">Facturación</p>
				<button class="delete"  @click.prevent="eventModalInvoice({ id: '', details: [], period: { month: '', year: '' } })" aria-label="close"></button>
				</header>
				<section class="modal-card-body">
          <p class="is-size-2">{{ getMonthName(invoiceSel.period.month, invoiceSel.period.year) }} / {{ invoiceSel.period.year }} </p>
          <p class="is-size-6 has-text-weight-semibold">{{ invoiceSel.company_name }}</p>
          <a v-if="!invoiceSel.paid && hasWritePermission" class="button is-rounded is-primary is-large has-text-weight-medium is-family-monospace" @click.prevent="confirmModal()">
            <span class="icon">
              <i class="fa-solid fa-hand-holding-dollar"></i>
            </span> 
            <div>${{ formatPrice(invoiceSel.total - invoiceSel.payment) }}</div>
          </a>
          <a v-else class="button is-rounded is-success is-large has-text-weight-medium is-family-monospace" disabled>
            <span>
              <i class="fa-solid fa-check"></i>
            </span>
            <div>${{ formatPrice(invoiceSel.total) }}</div>
          </a>
          <invoiceDetail :invoiceCompaniesProp="invoiceSel" @update-invoices="updateInvoice()" :hasWritePermission="hasWritePermission" ></invoiceDetail>
          <invoicePaymentsVue :invoiceCompaniesProp="invoiceSel" @delete-payment="deletePayment" :hasWritePermission="hasWritePermission" />
          <invoiceCompanyFiles :hasWritePermission="hasWritePermission" />
          <div class="modal" id="modalConfirmDeletePayment">
            <div class="modal-background"></div>
            <div class="modal-card">
              <header class="modal-card-head">
              <p class="modal-card-title">Acción Destructiva</p>
              <button class="delete"  @click.prevent="eventModalDeletePayment()" aria-label="close"></button>
              </header>
              <section class="modal-card-body">
                Confirmar ELIMINAR abono
              </section>
              <footer class="modal-card-foot has-text-centered inline-block">
                <button class="button is-danger" @click.prevent="deletePaymentConfirm()">Eliminar</button>
                <button class="button" @click.prevent="eventModalDeletePayment()">Cancel</button>
              </footer>
            </div>
          </div>
				</section>
			</div>
    </div>
    <div id="confirm-pay-modal" class="modal">
      <div class="modal-background"></div>
      <div class="modal-card">
        <div class="modal-card-body">
          <div class="box">
            <p>Confirmar pago?</p>
            <!-- Your content -->
          </div>
        </div>
        <footer class="modal-card-foot">
          <button class="button is-success" @click.prevent="toPay()">Sí</button>
          <button class="button" @click.prevent="confirmModal()">Cancelar</button>
        </footer>
      </div>
      <button class="modal-close is-large" aria-label="close"></button>
    </div>
    <div class="modal" id="modal-pay">
			<div class="modal-background"></div>
			<div class="modal-card">
				<header class="modal-card-head">
					<p class="modal-card-title">Abonar</p>
					<button class="delete"  @click.prevent="eventModalPay()" aria-label="close"></button>
				</header>
				<section class="modal-card-body">
					<label class="label" for="#concept">Monto:</label>
					<input
						v-model="pay.value"
						class="input"
						type="number"
						id="value"
						placeholder="Monto abono"
					>
				</section>
				<footer class="modal-card-foot">
					<button class="button is-success" @click.prevent="toManualPay()">Abonar</button>
					<button class="button" @click.prevent="eventModalPay()">Cancel</button>
				</footer>
			</div>
		</div>
	</section>
</template>
<script>
// import auth from '@/auth/index'
import invoiceDetail from '@/components/billing/invoiceDetailCompany.vue'
import invoiceCompanyFiles from '@/components/billing/invoiceCompanyFiles.vue'
import invoicePaymentsVue from '../billing/invoicePayments.vue'
export default {
  components: {
    invoiceDetail,
    invoiceCompanyFiles,
    invoicePaymentsVue
  },
  data: () => ({
    error: false,
    strError: '',
    invoices: [],
    invoiceSel: { id: '', details: [], period: { month: '', year: '' } },
    pay: {value: 1000, company_id: 0}
  }),
  props: {
      invoicesProp: Array,
      companyId: Number,
      company: Object,
      hasWritePermission: Boolean
  },
  methods: {
    eventModalInvoice (invoice) {
      this.invoiceSel = invoice
      const Modal = document.querySelector(`#modalInvoice`)
      Modal.classList.toggle('is-active')
    },
    eventModalPay () {
      const Modal = document.querySelector(`#modal-pay`)
      Modal.classList.toggle('is-active')
    },
    formatPrice(value) {
      let val = (value/1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    getMonthName (month, year) {
      const newDate = new Date(year, month - 1, 1)
      const monthName = newDate.toLocaleString('es-cl', { month: 'long' })
      return monthName.charAt(0).toUpperCase() + monthName.slice(1)
    },
    confirmModal() {
      const Modal = document.querySelector(`#confirm-pay-modal`)
      Modal.classList.toggle('is-active')
    },
    clickStatus(invoice) {
      this.invoiceSel = invoice
      this.confirmModal() 
    },
		async toPay() {
      const invoiceId = this.invoiceSel.id
      // console.log(invoiceId)
      this.$axios
      .post('billings/toPay', { invoiceCompany: { id: invoiceId} } )
      .then(response => {
        this.confirmModal()
        if(response.data.result === 'OK')
          // this.invoiceSel.paid = true
          this.updateInvoice2()
        // this.$router.push({ name: "BillingView", params: { id: this.invoiceCompany.billing_id }})
      })
    },
    async updateInvoice() {
      await this.$axios
      .get('billings/invoiceGet', { params: { invoiceCompanyId: this.invoiceSel.id } })
      .then(response => {
        console.log(response.data.InvoiceCompany)
        this.invoiceSel = response.data.InvoiceCompany
      })
      .catch(error => console.log(error))
      // this.eventModalPay()
      this.$emit('update-invoices')
    },
    async updateInvoice2() {
      await this.$axios
      .get('billings/invoiceGet', { params: { invoiceCompanyId: this.invoiceSel.id } })
      .then(response => {
        console.log(response.data.InvoiceCompany)
        this.invoiceSel = response.data.InvoiceCompany
      })
      .catch(error => console.log(error))
      this.$emit('update-invoices')
    },
    async toManualPay() {
      await this.$axios.post('billings/toManualPay', { pay: this.pay }).then(response => {
        if(response.data.result === 'OK')
          this.eventModalPay()
          this.$emit('update-invoices')
          

      })
    },
    async deletePayment(payment) {
      console.log('2 deletePayment', payment)
      this.tmpPayment = payment
      this.eventModalDeletePayment()

      },
    eventModalDeletePayment () {
      const Modal = document.querySelector(`#modalConfirmDeletePayment`)
      Modal.classList.toggle('is-active')
      },
    async deletePaymentConfirm() {
      await this.$axios
      .post('payment/delete', { payment: this.tmpPayment })
      .then(response => {
        const result = response.data.result
        if (result === 'OK') {
          this.eventModalDeletePayment()
          this.updateInvoice()
        } else {
          alert('Ha ocurrido un error')
        }
      })
      .catch(error => console.error(error))
    }
  },
	watch: { 
		invoicesProp: function(newVal) { // watch it
			this.invoices = newVal
		},
    companyId: function(newVal) {
      this.pay.companyId = parseInt(newVal)
    }
	}
}
</script>