<template>
	<div>
		<div class="column is-full">
			<div class="card events-card is-size-6">
				<header class="card-header">
					<p class="card-header-title">
					Contratos
					</p>
					<a href="#" class="card-header-icon" aria-label="more options">
						<span class="icon">
							<i class="fa fa-angle-down" aria-hidden="true"></i>
						</span>
					</a>
				</header>
				<div class="card-table">
					<div class="content">
						<table class="table is-fullwidth is-striped is-size-7">
							<thead v-if="this.contracts.length > 0">
								<th></th>
								<th>Concepto</th>
								<th>Divisa</th>
								<th>Monto</th>
								<th></th>
							</thead>
							<tbody>
								<tr v-if="this.contracts.length === 0">
									<td width="5%"><i class="fa fa-bell-o"></i></td>
									<td>Sin Información</td>
								</tr>
								<tr v-for="localContract in this.contracts" :key="localContract.id">
									<td width="5%"><i class="fa fa-bell-o"></i></td>
									<td>{{ localContract.concept }}</td>
									<td>{{ localContract.currency_description }}</td>
									<td>${{ formatPrice(localContract.value) }}</td>
									<td class="level-right">
										<div class="buttons">
											<a class="button is-small is-primary is-light is-info" @click.prevent="getContract(localContract.id)">
												<span class="icon">
													<i class="fa fa-edit"></i>
												</span>
											</a>
											<a v-if="hasWritePermission" class="button is-small is-primary is-light is-danger has-text-left" @click.prevent="deleteContract(localContract.id)">
												<span class="icon">
													<i class="fa fa-trash"></i>
												</span>
											</a>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<footer class="card-footer">
					<a v-if="hasWritePermission" @click.prevent="eventModal()" class="card-footer-item button is-primary is-light">
						<span class="icon is-small">
							<i class="fa fa-plus-square"></i>
						</span>
						<span>Nuevo</span>
					</a>
				</footer>
			</div>
		</div>
		<!-- MODAL -->
		<div class="modal" id="modalContract">
			<div class="modal-background"></div>
			<div class="modal-card">
				<header class="modal-card-head">
					<p class="modal-card-title">Agregar servicio o producto</p>
					<button class="delete"  @click.prevent="eventModal()" aria-label="close"></button>
				</header>
				<section class="modal-card-body">
					<label class="label" for="#concept">Descripción</label>
					<input
						v-model="tmpContract.concept"
						class="input"
						type="text"
						id="concept"
						placeholder="Descripción"
					>
					<label class="label" for="#currency">Divisa</label>
					<div class="select is-rounded" id="currency">
						<select v-model="tmpContract.currency_id">
							<option v-for="currency in currencies" :key="currency.id" v-bind:value="currency.id">{{ currency.description }}</option>
						</select>
					</div>
					<label class="label" for="#value">Valor</label>
					<input
						v-model="tmpContract.value"
						class="input"
						type="Number"
						id="value"
						placeholder="Valor"
					>
					<!-- <label class="label" for="#value">Es periodico:</label>
					<input
						v-model="tmpContract.isPeriodico"
						type="checkbox"
						id="jack"
						value="Jack"
					>-->

				</section>
				<footer class="modal-card-foot">
					<button v-if="hasWritePermission" class="button is-success" @click.prevent="add()">Agregar</button>
					<button v-if="hasWritePermission" class="button" @click.prevent="cancelModal()">Cancel</button>
					<button v-else class="button" @click.prevent="cancelModal()">Cerrar</button>
				</footer>
			</div>
		</div>
	</div>
</template>
<script>
export default {
  data: () => ({
    contracts: [],
    error: false,
    strError: '',
    tmpContract: { id: '', concept: '', currency_id:1, value: '', company_id: '' }
  }),
  props: {
		company: Object,
		currencies: Array,
		hasWritePermission: Boolean
  },
  methods: {
		cancelModal () {
			this.tmpContract.concept = ''
			this.tmpContract.value = ''
			this.tmpContract.id = ''
			this.eventModal()
		},
    eventModal () {
      var Modal = document.querySelector(`#modalContract`)
      Modal.classList.toggle('is-active')
    },
    add () {
			this.tmpContract.company_id = this.company.id
			this.$axios
			.post('company/createContract', { contract: this.tmpContract })
			.then(response => {
				const result = response.data.result
				console.log(result)
        this.tmpContract.concept = ''
				this.tmpContract.value = ''
				this.tmpContract.id = ''
				this.updateList()
				this.eventModal()
			})
			.catch(error => console.log(error))
    },
		deleteContract (contractId) {
			this.tmpContract.id = contractId
			this.$axios
			.post('company/deleteContract', { contract: this.tmpContract })
			.then(response => {
				const result = response.data.result
				if (result === 'OK') {
					this.updateList()
				} else {
					alert('Ha ocurrido un error')
				}
			})
			.catch(error => console.log(error))
    },
		getContract (contractId) {
			this.$axios
			.get('company/getContract', { params: { contract_id: contractId } })
			.then(response => {
				this.tmpContract = response.data.contract
				this.eventModal()
			})
		},
		async updateList() {
			// console.log('updateList()')
			this.$axios
			.get('company/getContracts', { params: { company_id: this.company.id } })
			.then(response => {
				// console.log(response.data.secrets)
				this.contracts = response.data.contracts
			})
			.catch(error => console.log(error))
    },
		formatPrice(value) {
			let val = (value/1).toFixed(2).replace('.', ',')
			return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }
  },
	watch: {
		company: function(newVal) { // watch it
			this.company = newVal
			this.updateList()
		}
	}
}
</script>