<template>
	<div>
		<div class="column is-full">
			<div class="card events-card is-size-6">
				<header class="card-header">
					<p class="card-header-title">
					Representantes Legales
					</p>
					<a href="#" class="card-header-icon" aria-label="more options">
						<span class="icon">
							<i class="fa fa-angle-down" aria-hidden="true"></i>
						</span>
					</a>
				</header>
				<div class="card-table">
					<div class="content">
						<table class="table is-fullwidth is-striped is-size-7">
							<thead v-if="legalRepresentatives.length > 0">
								<th></th>
								<th>Rut</th>
								<th>Nombre</th>
								<th></th>
							</thead>
							<tbody>
								<tr v-if="legalRepresentatives.length === 0">
									<td width="5%"><i class="fa fa-bell-o"></i></td>
									<td>Sin información</td>
								</tr>
								<tr v-for="localLegalRepresentatives in legalRepresentatives" :key="localLegalRepresentatives.rut">
									<td width="5%"><i class="fa fa-bell-o"></i></td>
									<td>{{ localLegalRepresentatives.rut }}</td>
									<td>{{ localLegalRepresentatives.name }}</td>
									<td class="level-right">
										<div class="buttons">
											<a class="button is-small is-primary is-light is-info" @click.prevent="getLP(localLegalRepresentatives.id)">
												<span class="icon">
													<i class="fa fa-edit"></i>
												</span>
											</a>
											<a v-if="hasWritePermission" class="button is-small is-primary is-light is-danger has-text-left" @click.prevent="deleteLP(localLegalRepresentatives.id)">
												<span class="icon">
													<i class="fa fa-trash"></i>
												</span>
											</a>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<footer class="card-footer">
					<a v-if="hasWritePermission" @click.prevent="eventModal()" class="card-footer-item button is-primary is-light">
						<span class="icon is-small">
							<i class="fa fa-plus-square"></i>
						</span>
						<span>Nuevo</span>
					</a>
				</footer>
			</div>
		</div>
		<div class="modal" id="modalModal">
			<div class="modal-background"></div>
			<div class="modal-card">
				<header class="modal-card-head">
				<p class="modal-card-title">Agregar Representante legal</p>
				<button class="delete"  @click.prevent="eventModal()" aria-label="close"></button>
				</header>
				<section class="modal-card-body">
				<label class="label" for="#personRut">Rut:</label>
				<input
					v-model="tmpLegalRepresentative.rut"
					class="input"
					type="text"
					id="personRut"
					placeholder="rut"
				>
				<label class="label" for="#name">Nombre:</label>
				<input
					v-model="tmpLegalRepresentative.name"
					class="input"
					type="text"
					id="personName"
					placeholder="Nombre"
				>
				</section>
				<footer class="modal-card-foot">
					<button v-if="hasWritePermission" class="button is-success" @click.prevent="add()">Agregar</button>
					<button v-if="hasWritePermission" class="button" @click.prevent="cancelModal()">Cancelar</button>
					<button v-else class="button" @click.prevent="cancelModal()">Cerrar</button>
				</footer>
			</div>
    </div>
	</div>
</template>
<script>
export default {
  data: () => ({
    legalRepresentatives: [],
    error: false,
    strError: '',
    tmpLegalRepresentative: { id: '', rut: '', name: '', company_id: ''}
  }),
  props: {
      company: Object,
			hasWritePermission: Boolean
  },
  methods: {
		cancelModal() {
			this.tmpLegalRepresentative.id = ''
			this.tmpLegalRepresentative.rut = ''
			this.tmpLegalRepresentative.name = ''
			this.tmpLegalRepresentative.company_id = ''
			this.eventModal()
		},
    eventModal () {
      var Modal = document.querySelector(`#modalModal`)
      Modal.classList.toggle('is-active')
    },
    add () {
			this.tmpLegalRepresentative.company_id = this.company.id
			this.$axios
			.post('company/createLegalRepresentative', { legalRepresentative: this.tmpLegalRepresentative })
			.then(response => {
				const result = response.data.result
				if (result === 'OK') {
					this.tmpLegalRepresentative.id = ''
					this.tmpLegalRepresentative.rut = ''
					this.tmpLegalRepresentative.name = ''
					this.updateList()
					this.eventModal()
				} else {
					alert('Ha ocurrido un error')
				}
				// console.log(result)
			})
			.catch(error => console.log(error))
    },
		deleteLP (lpId) {
			this.tmpLegalRepresentative.id = lpId
			this.$axios
			.post('company/deleteLegalRepresentative', { legalRepresentative: this.tmpLegalRepresentative })
			.then(response => {
				const result = response.data.result
				if (result === 'OK') {
					this.updateList()
				} else {
					alert('Ha ocurrido un error')
				}
				// console.log(result)
			})
			.catch(error => console.log(error))
		},
		getLP (LPId) {
			this.$axios
			.get('company/getLegalRepresentative', { params: { legal_representative_id: LPId } })
			.then(response => {
				this.tmpLegalRepresentative = response.data.legalRepresentative
				this.eventModal()
			})
		},
		async updateList() {
			this.$axios
			.get('company/getLegalRepresentatives', { params: { company_id: this.company.id } })
			.then(response => {
				// console.log(response.data.legalRepresentatives)
				this.legalRepresentatives = JSON.parse(response.data.legalRepresentatives)
			})
			.catch(error => console.log(error))
    }
  },
	watch: { 
		company: function(newVal) { // watch it
			this.company = newVal
			this.updateList()
		}
	},
	computed: {
		fullRut: {
			get: function() {
				return this.company.rut + '-' + this.company.dv
			},
			set: function (newValue) {
				const local_rut = newValue.replaceAll('.', '').replaceAll('-', '').slice(0, -1)
				const local_dv = newValue.slice(-1)
				// console.log('rut: ' + local_rut)
				// console.log('dv: ' + local_dv)
				this.company.rut = local_rut
				this.company.dv = local_dv
			}
		}
	}
}
</script>