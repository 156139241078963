<template>
	<section>
		<div class="column">
			<div class="card events-card is-size-6">
				<header class="card-header">
					<p class="card-header-title">
					Contactos
					</p>
					<a href="#" class="card-header-icon" aria-label="more options">
						<span class="icon">
							<i class="fa fa-angle-down" aria-hidden="true"></i>
						</span>
					</a>
				</header>
				<div class="card-table">
					<div class="table-container">
						<table class="table is-fullwidth is-striped is-size-7">
							<thead v-if="contacts.length > 0">
								<th></th>
								<th class="is-hidden-mobile">Rut</th>
								<th>Nombre</th>
								<th class="is-hidden-mobile">Fono</th>
								<th>Email</th>
								<th></th>
							</thead>
							<tbody>
								<tr v-if="contacts.length === 0">
									<td width="5%"><i class="fa fa-bell-o"></i></td>
									<td>Sin contactos</td>
								</tr>
								<tr v-for="localContact in contacts" :key="localContact.rut">
									<td width="5%"><i class="fa fa-bell-o"></i></td>
									<td class="is-hidden-mobile">{{ getFullRut(localContact.rut) }}</td>
									<td>{{ localContact.name }}</td>
									<td class="is-hidden-mobile">{{ localContact.phone }}</td>
									<td>{{ localContact.email }}</td>
									<td class="level-right">
										<div class="buttons has-text-right">
											<a class="button is-small is-primary is-light is-info" @click.prevent="getContact(localContact.id)" >
												<span class="icon">
													<i class="fa fa-edit"></i>
												</span>
											</a>
											<a v-if="hasWritePermission" class="button is-small is-primary is-light is-danger has-text-left" @click.prevent="deleteContact(localContact.id)">
												<span class="icon">
													<i class="fa fa-trash"></i>
												</span>
											</a>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<footer class="card-footer">
					<a v-if="hasWritePermission" @click.prevent="eventModalContact()" class="card-footer-item button is-primary is-light">
						<span class="icon is-small">
							<i class="fa fa-plus-square"></i>
						</span>
						<span>Nuevo</span>
					</a>
				</footer>
			</div>
		</div>
		<div class="modal" id="modalContact">
			<div class="modal-background"></div>
			<div class="modal-card">
				<header class="modal-card-head">
				<p class="modal-card-title">Agregar Contacto</p>
				<button class="delete"  @click.prevent="eventModalContact()" aria-label="close"></button>
				</header>
				<section class="modal-card-body">
				<label class="label" for="#personRut">Rut:</label>
				<input
					v-model="tmpContact.rut"
					class="input"
					type="text"
					id="personRut"
					placeholder="rut"
				>
				<label class="label" for="#name">Nombre:</label>
				<input
					v-model="tmpContact.name"
					class="input"
					type="text"
					id="personName"
					placeholder="Nombre"
				>
				<label class="label" for="#personPhone">Fono:</label>
				<input
					v-model="tmpContact.phone"
					class="input"
					type="tel"
					id="personPhone"
					placeholder="Fono"
				>
				<label class="label" for="#personEmail">Email:</label>
				<input
					v-model="tmpContact.email"
					class="input"
					type="tel"
					id="personEmail"
					placeholder="Email"
				>
				</section>
				<footer class="modal-card-foot">
					<button v-if="hasWritePermission" class="button is-success" @click.prevent="addContact()">Agregar</button>
					<button v-if="hasWritePermission" class="button" @click.prevent="cancelModal()">Cancel</button>
					<button v-else class="button" @click.prevent="cancelModal()">Cerrar</button>
				</footer>
			</div>
    </div>
	</section>
</template>
<script>
// import auth from '@/auth/index'
export default {
  data: () => ({
    contacts: [],
    error: false,
    strError: '',
    tmpContact: { id: '', rut: '', name: '', phone: '', email: '', company_id: '' }
  }),
  props: {
      company: Object,
			hasWritePermission: Boolean
  },
  methods: {
		cancelModal () {
			this.tmpContact.id = ''
			this.tmpContact.rut = ''
			this.tmpContact.name = ''
			this.tmpContact.phone = ''
			this.tmpContact.email = ''
			this.eventModalContact()
		},
    eventModalContact () {
      var Modal = document.querySelector(`#modalContact`)
      Modal.classList.toggle('is-active')
    },
    addContact () {
			this.tmpContact.company_id = this.company.id
			this.$axios
			.post('company/createContact', { contact: this.tmpContact })
			.then(response => {
				const result = response.data.result
				if (result === 'OK') {
					this.tmpContact.rut = ''
					this.tmpContact.name = ''
					this.tmpContact.phone = ''
					this.tmpContact.email = ''
					this.updateList()
					this.eventModalContact()
				} else {
					alert('Ha ocurrido un problema')
				}

			})
			.catch(error => console.log(error))

      // this.contacts.push({rut: this.tmpContact.rut, name: this.tmpContact.name, phone: this.tmpContact.phone, email: this.tmpContact.email})
      
    },
		deleteContact (contactId) {
			this.tmpContact.id = contactId
			this.$axios
			.post('company/deleteContact', { contact: this.tmpContact })
			.then(response => {
				const result = response.data.result
				if (result === 'OK') {
					this.updateList()
				} else {
					alert('Ha ocurrido un problema')
				}

			})
			.catch(error => console.log(error))

      // this.contacts.push({rut: this.tmpContact.rut, name: this.tmpContact.name, phone: this.tmpContact.phone, email: this.tmpContact.email})
      
    },
		getContact (contactId) {
			this.tmpContact.id = contactId
			// const token = auth.getTokenLogged()
			// this.$axios.defaults.headers.head['Authorization'] =  `Bearer: ${token}`
			// this.$axios.defaults.headers.get['Authorization'] =  `Bearer ${token}`
			// this.$axios.defaults.withCredentials = true;
			// this.$axios.defaults.headers.post['Authorization'] =  `Bearer ${token}`
			// console.log(this.$axios.defaults.headers)
			// console.log(contactId)
			// console.log(`Bearer ${token}`)
			this.$axios
			// .get('company/getContact', { params: { contact_id: contactId }, headers: { Accept: 'application/json', "Content-Type": "application/json", Authorization: `Bearer ${token}` } })
			.get('company/getContact', { params: { contact_id: contactId } })
			.then(response => {
				// console.log(response.data.contact)
				this.tmpContact = response.data.contact
				
				this.eventModalContact()
			})
			.catch(error => console.log(error))

      // this.contacts.push({rut: this.tmpContact.rut, name: this.tmpContact.name, phone: this.tmpContact.phone, email: this.tmpContact.email})
      
    },
		async updateList() {
			const companyId = this.$route.params.id
			this.company.id = companyId
			// print(this.company.id)
			this.$axios
			.get('company/getContacts', { params: { company_id: this.company.id } })
			.then(response => {
				// console.log(JSON.parse(response.data.contacts))
				this.contacts = JSON.parse(response.data.contacts)
			})
			.catch(error => console.log(error))
    },
		getFullRut(personRut) {
			let body = personRut.replace('-', '')
			let dv = body[body.length -1 ]
			body = body.substr(0, body.length -1)
			let val = body.replace(/\B(?=(\d{3})+(?!\d))/g, ".") + '-' + dv
			return val
		}
		
  },
	watch: { 
		company: function(newVal) { // watch it
			this.company = newVal
			this.updateList()
		}
	}
}
</script>