<template>
	<div>
		<div class="column is-full">
			<div class="card events-card is-size-6">
				<header class="card-header">
					<p class="card-header-title">
					Socios
					</p>
					<a href="#" class="card-header-icon" aria-label="more options">
						<span class="icon">
							<i class="fa fa-angle-down" aria-hidden="true"></i>
						</span>
					</a>
				</header>
				<div class="card-table">
					<div class="content">
						<table class="table is-fullwidth is-striped is-size-7">
							<thead v-if="partners.length > 0">
								<th></th>
								<th class="is-hidden-mobile">Rut</th>
								<th>Nombre</th>
								<th>Participación</th>
								<th class="has-text-left"></th>
							</thead>
							<tbody>
								<tr v-if="partners.length === 0">
									<td width="5%"><i class="fa fa-bell-o"></i></td>
									<td>Sin socios</td>
								</tr>
								<tr v-for="localPartner in partners" :key="localPartner.rut">
									<td width="5%"><i class="fa fa-bell-o"></i></td>
									<td class="is-hidden-mobile">{{ localPartner.rut }}</td>
									<td>{{ localPartner.name }}</td>
									<td>{{ localPartner.participation_perc }}</td>
									<td class="level-right">
										<div class="buttons">
											<a class="button is-small is-primary is-light is-info" @click.prevent="getPartner(localPartner.id)">
												<span class="icon">
													<i class="fa fa-edit"></i>
												</span>
											</a>
											<a v-if="hasWritePermission" class="button is-small is-primary is-light is-danger" @click.prevent="deletePartner(localPartner.id)">
												<span class="icon">
													<i class="fa fa-trash"></i>
												</span>
											</a>										
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<footer class="card-footer">
					<a v-if="hasWritePermission" @click.prevent="eventModalPartners()" class="card-footer-item button is-primary is-light">
						<span class="icon is-small">
							<i class="fa fa-plus-square"></i>
						</span>
						<span>Nuevo</span>
					</a>
				</footer>
			</div>
		</div>
		<div class="modal" id="modalPartners">
			<div class="modal-background"></div>
			<div class="modal-card">
				<header class="modal-card-head">
				<p class="modal-card-title">Agregar Socio</p>
				<button class="delete"  @click.prevent="eventModalPartners()" aria-label="close"></button>
				</header>
				<section class="modal-card-body">
				<label class="label" for="#personRut">Rut:</label>
				<input
					v-model="tmpPartner.rut"
					class="input"
					type="text"
					id="personRut"
					placeholder="rut"
				>
				<label class="label" for="#name">Nombre:</label>
				<input
					v-model="tmpPartner.name"
					class="input"
					type="text"
					id="personName"
					placeholder="Nombre"
				>
				<label class="label" for="#personPartipation">Participación %:</label>
				<input
					v-model="tmpPartner.participation_perc"
					class="input"
					type="number"
					min="1" 
					max="100" 
					step="0.5"
					id="personPartipation"
					placeholder="Participación"
				>
				<label class="label" for="#personBirthday">Fecha nacimiento:</label>
				<input
					v-model="tmpPartner.birthday"
					class="input"
					type="date"
					id="personBirthday"
				>
				</section>
				<footer class="modal-card-foot">
					<button v-if="hasWritePermission" class="button is-success" @click.prevent="addPartner()">Agregar</button>
					<button v-if="hasWritePermission" class="button" @click.prevent="cancelModal()">Cancelar</button>
					<button v-else class="button" @click.prevent="cancelModal()">Cerrar</button>
				</footer>
			</div>
    </div>
	</div>
</template>
<script>
export default {
  data: () => ({
    partners: [],
    error: false,
    strError: '',
    tmpPartner: { id: '', rut: '', name: '', participation_perc: 100, birthday: '', company_id: ''}
  }),
  props: {
      company: Object,
			hasWritePermission: Boolean
  },
  methods: {
		cancelModal () {
			this.tmpPartner.id = ''
			this.tmpPartner.rut = ''
			this.tmpPartner.name = ''
			this.participation_perc = 100
			this.birthday = ''
			this.eventModalPartners()
		},
    eventModalPartners () {
      var Modal = document.querySelector(`#modalPartners`)
      Modal.classList.toggle('is-active')
    },
    addPartner () {
			this.tmpPartner.company_id = this.company.id
			this.$axios
			.post('company/createPartner', { partner: this.tmpPartner })
			.then(response => {
				const result = response.data.result
				if (result === 'OK') {
					this.tmpPartner.id = ''
					this.tmpPartner.rut = ''
					this.tmpPartner.name = ''
					this.tmpPartner.participation_perc = ''
					this.tmpPartner.birthday = ''
					this.updateList()
					this.eventModalPartners()
				} else {
					alert('Ha ocurrido un error')
				}
				// console.log(result)
			})
			.catch(error => console.log(error))
    },
		deletePartner (partnerId) {
			this.tmpPartner.id = partnerId
			this.$axios
			.post('company/deletePartner', { partner: this.tmpPartner })
			.then(response => {
				const result = response.data.result
				if (result === 'OK') {
					this.updateList()
				} else {
					alert('Ha ocurrido un error')
				}
			})
			.catch(error => console.log(error))
    },
		getPartner (partnerId) {
			this.$axios
			.get('company/getPartner', { params: { partner_id: partnerId } })
			.then(response => {
				this.tmpPartner = response.data.partner
				this.eventModalPartners()
			})
		},
		async updateList() {
			this.$axios
			.get('company/getPartners', { params: { company_id: this.company.id } })
			.then(response => {
				// console.log(response.data.partners)
				this.partners = JSON.parse(response.data.partners)
			})
			.catch(error => console.log(error))
    }
  },
	watch: { 
		company: function(newVal) { // watch it
			this.company = newVal
			this.updateList()
		}
	},
	computed: {
		fullRut: {
			get: function() {
				return this.company.rut + '-' + this.company.dv
			},
			set: function (newValue) {
				const local_rut = newValue.replaceAll('.', '').replaceAll('-', '').slice(0, -1)
				const local_dv = newValue.slice(-1)
				// console.log('rut: ' + local_rut)
				// console.log('dv: ' + local_dv)
				this.company.rut = local_rut
				this.company.dv = local_dv
			}
		}
	}
}
</script>